import type { StoryblokStory } from "storyblok-generate-ts"
import type { PageStoryblok } from "~/component-types-sb"
import { environmentKey } from "~/injection-symbols"

export const useSbStory = async(url: string) => {
  const environment = inject(environmentKey)

  const storyblokEnv = environment === "development" || environment === "staging" ? "draft" : "published"

  const story = await useAsyncStoryblok(
    url,
    {
      version: storyblokEnv,
      resolve_links: "story",
      resolve_relations: [
        "page.header",
        "page.footer",
        "BlogArticle.header",
        "BlogArticle.footer",
        "BlogArticleOverview.header",
        "BlogArticleOverview.footer",
        "BlogSearchResults.header",
        "BlogSearchResults.footer",
        "form-secrets.successPage",
      ],
    },
    {
      resolveLinks: "story",
    },
  )
    .then((res: Ref<StoryblokStory<PageStoryblok>>) => {
      if (!res.value) throw new Error("Story not found")
      return res
    })
    .catch(() => {
      throw createError({ statusCode: 404, statusMessage: "Seite nicht gefunden" })
    })

  return story
}
